import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
  ButtonIcon,
  ButtonSlider,
  HorizontalSlider,
  LayoutFluid,
  VerticalDots,
} from '../components'
import { Play, Quote, Cross } from '../components/icons'
import { When } from '../styling/when'
import Img from 'gatsby-image'
import { useMediaMatch } from '../util'
import { black, white } from '../styling/colors'

const TitleContainer = styled.div`
  position: relative;
  background: ${black};
  // height: calc(100vh - 600px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SlidesContainer = styled.div`
  padding-top: 10px;
  position: relative;

  ${When.Large} {
    padding-top: 32px;
  }
`

const UpperBox = styled.div`
  /* display: none; */

  ${When.Large} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
`
const Title = styled.h1`
  color: ${white};
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;

  ${When.Large} {
    display: block;
    font-size: 48px;
    font-family: 'poppins';

    margin: 0;
  }
`
const SubText = styled.p`
  color: ${white};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  ${When.Large} {
    display: block;
    font-size: 14px;
  }
`

const Cont = styled.div`
  max-width: 144px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const H = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: white;

  ${When.Large} {
    font-size: 48px;
  }
`
const P = styled.p`
  font-size: 12px;
  color: white;

  ${When.Large} {
    font-size: 14px;
    margin-top: 0px;
  }
`

const Slides = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 164px;
  position: relative;
`

const TestimonialsSection = styled.div`
  // background: white;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -110px;
  ${When.Large} {
    margin-top: -40px;
  }
`
const Footer = styled.div`
  width: 100%;
  height: 212px;
  background-color: white;
`

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  /* width: 100%; */
  height: 220px;
  padding: 0 12px;
  ${When.Large} {
    height: 250px;
  }
`

const Box1 = styled.div`
  /* max-width: 327px; */

  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  transition: opacity 0.2s;

  flex-direction: column;
  display: flex;

  ${When.Large} {
    width: 97%;
    /* max-width: 448px; */
    /* width: 90%; */
    box-sizing: border-box;
    padding: 30px 20px;
    // padding-top: 49px;
    // padding-bottom: 42px;
    // padding-right: 57px;
    // padding-left: 48px;
  }
`

const Up = styled.div`
  box-sizing: border-box;
  /* padding-left: 48px; */
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;

  ${When.Large} {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }

  display: flex;
  align-items: flex-start;
  margin-bottom: auto;
`

const Text = styled.p`
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400px;
  line-height: 18px;
  max-width: 450px;
  width: 100%;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${When.Large} {
    -webkit-line-clamp: 7;
    max-width: unset;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    margin-top: 0;
    margin-left: 20px;
  }

  white-space: normal;
  text-align: left;
`

const Down = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* padding-left: 48px; */
  margin-top: 16px;
`

const ImageElipce = styled.div`
  box-sizing: border-box;
  max-width: 32px;
  width: 100%;
  height: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  margin-right: 12px;
`

const PersonGatsbyImage = styled(Img)`
  border-radius: 50%;
`

const Name = styled.h3`
  margin-top: 0;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
`

const Person = styled.div`
  max-width: 160px;
  box-sizing: border-box;
  margin-top: 0;
  text-align: left;

  ${When.Medium} {
    max-width: 180px;
  }

  ${When.Large} {
    max-width: none;
  }
`

const Job = styled.p`
  font-size: 12px;
  color: #9e9e9e;
  line-height: 18px;
  font-weight: 600px;
  font-style: normal;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Points = styled.div`
  display: none;

  ${When.Large} {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-0%, -50%);
    display: block;
    color: white;
  }
`

const TestimonialPlayButton = styled(ButtonIcon)`
  margin-right: 16px;
  ${({ invisible }) => (invisible ? 'visibility: hidden;' : '')}
`

const StyledHorizontalSlider = styled(HorizontalSlider)`
  width: 100%;
  max-width: 1100px;
  // position: absolute;
  // top: -130px;
  // left: 50%;
  // transform: translate(-50%);

  ${When.Medium} {
    width: 90%;
  }
`

const TestimonialVideoContainer = styled.div`
  background: ${black};
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  ${When.Medium} {
    padding: 70px;
    padding-bottom: 30px;
  }
`

const TestimonialVideWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
`

const TestimonialVideoCloseButton = styled.div`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  ${When.Medium} {
    right: 38px;
    top: 38px;
  }
`

const horizontalSlideritemListContainerStyles = css`
  // padding: 100px 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 270px;
`

const TestimonialVideoAuthor = styled.div`
  margin-top: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TestimonialVideoAuthorTextsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`

const TestimonialVideoImageEclipse = styled.div`
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;
`

const TestimonialVideoAuthorName = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #f5f5f5;
  margin-left: 12px;
`

const TestimonialVideoAuthorDot = styled.div`
  background: #e0e0e0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 8px;
`

const TestimonialVideoAuthorTitle = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`

const StyledVerticalDots = styled(VerticalDots)`
  display: none;
  position: absolute;
  top: 64px;
  right: 140px;

  ${When.Large} {
    display: flex;
    right: 164px;
  }

  @media screen and (max-width: 1450px) {
    right: 82px;
  }
`

const CountContainer = styled.div`
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${When.Large} {
    display: none;
  }
`

const layoutStyles = `
justify-content: center;
min-height: unset;
${When.Large} {
  // min-height: 670px;
}
`

export default function Partners({ location }) {
  const { allStrapiTestimonial } = useStaticQuery(query)

  const [selectedTestimonialIndex, setSelectedTestimonialIndex] = useState(null)

  const { large } = useMediaMatch()

  const [currentPage, setCurrentPage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)

  const hasPrevPage = currentPage > 0
  const hasNextPage = currentPage < numberOfPages - 1

  const handlePrevPage = useCallback(() => {
    if (hasPrevPage) {
      setCurrentPage(currentPage - 1)
    }
  }, [currentPage, numberOfPages])

  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, numberOfPages])

  const selectedTestimonial = useMemo(() => {
    return selectedTestimonialIndex !== null
      ? allStrapiTestimonial.edges[selectedTestimonialIndex].node
      : null
  }, [selectedTestimonialIndex, allStrapiTestimonial])

  return (
    <LayoutFluid location={location} styles={layoutStyles}>
      {selectedTestimonial ? (
        <TestimonialVideoContainer>
          <TestimonialVideoCloseButton
            onClick={() => setSelectedTestimonialIndex(null)}
          >
            <Cross stroke={white} />
          </TestimonialVideoCloseButton>
          <div style={{ maxWidth: '125vh', maxHeight: '100vh', width: '100%' }}>
            <TestimonialVideWrapper>
              <iframe
                style={{ position: 'absolute', top: 0, left: 0 }}
                width="100%"
                height="100%"
                src={`${selectedTestimonial.video.replace(
                  'youtu.be/',
                  'youtube.com/embed/'
                )}?rel=0&modestbranding=1&autoplay=1&loop=1`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </TestimonialVideWrapper>
          </div>
          <TestimonialVideoAuthor>
            <TestimonialVideoImageEclipse>
              <PersonGatsbyImage
                fixed={
                  selectedTestimonial.personPhoto?.localFile?.childImageSharp
                    ?.fixed
                }
              />
            </TestimonialVideoImageEclipse>
            <TestimonialVideoAuthorTextsContainer>
              <TestimonialVideoAuthorName>
                {selectedTestimonial.personName}
              </TestimonialVideoAuthorName>
              <TestimonialVideoAuthorDot />
              <TestimonialVideoAuthorTitle>
                {selectedTestimonial.personTitle}
              </TestimonialVideoAuthorTitle>
            </TestimonialVideoAuthorTextsContainer>
          </TestimonialVideoAuthor>
        </TestimonialVideoContainer>
      ) : null}
      <TitleContainer>
        <div
          style={{
            backgroundColor: '#212121',
            height: '100vh',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: -1,
          }}
        />
        <UpperBox>
          <Title>Testimonials</Title>
          <SubText>See what our clients think about us and our service</SubText>
        </UpperBox>
      </TitleContainer>

      <SlidesContainer>
        <div
          style={{
            backgroundColor: '#212121',
            height: '100vh',
            position: 'absolute',
            bottom: 150,
            width: '100%',
            zIndex: -1,
          }}
        />
        <Slides>
          <Cont>
            <ButtonSlider
              direction="left"
              colors="outlineWhiteOnBlack"
              onClick={() => handlePrevPage()}
              disabled={!hasPrevPage}
            />
            <ButtonSlider
              direction="right"
              colors="outlineWhiteOnBlack"
              onClick={() => handleNextPage()}
              disabled={!hasNextPage}
            />
          </Cont>
          <StyledVerticalDots
            numberOfDots={numberOfPages}
            activeDot={currentPage}
            colors="light"
          />
        </Slides>
        <TestimonialsSection>
          <StyledHorizontalSlider
            itemListContainerStyles={horizontalSlideritemListContainerStyles}
            keys={item => item.id}
            showControls={false}
            itemListStyles={css`
              height: 300px;
              box-sizing: border-box;
              padding: 0 16px;
            `}
            items={allStrapiTestimonial.edges.map(({ node }, i: number) => ({
              ...node,
              arrayIndex: i,
            }))}
            itemTemplate={(node: any, isVisible: boolean) => (
              <TestimonialContainer>
                <Box1 style={{ opacity: isVisible ? 1 : 0 }}>
                  <Up>
                    <Quote />
                    <Text>{node.content}</Text>
                  </Up>

                  <Down>
                    <TestimonialPlayButton
                      colors="light"
                      stroke={true}
                      icon={<Play />}
                      onClick={() => {
                        setSelectedTestimonialIndex(node.arrayIndex)
                      }}
                      invisible={!node.video}
                    />
                    <ImageElipce>
                      <PersonGatsbyImage
                        fixed={
                          node.personPhoto?.localFile?.childImageSharp?.fixed
                        }
                      />
                    </ImageElipce>
                    <Person>
                      <Name>{node.personName}</Name>
                      <Job>{node.personTitle}</Job>
                    </Person>
                  </Down>
                </Box1>
              </TestimonialContainer>
            )}
            onChangePage={() => null}
            onPageNumCalculated={value => setNumberOfPages(value)}
            page={currentPage}
            pageSize={large ? 2 : 1}
            slideBy="page"
          />
          {/* <Footer></Footer> */}
        </TestimonialsSection>
      </SlidesContainer>
      <CountContainer>
        <span>{currentPage + 1}</span>
        <span
          style={{
            marginLeft: 8,
            marginRight: 8,
            color: '#9E9E9E',
            fontWeight: 500,
          }}
        >
          |
        </span>
        <span>{numberOfPages}</span>
      </CountContainer>
    </LayoutFluid>
  )
}

const query = graphql`
  query {
    allStrapiTestimonial(sort: { fields: orderNumber }) {
      edges {
        node {
          id
          personName
          personPhoto {
            id
            localFile {
              childImageSharp {
                fixed(width: 32, height: 32) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          personTitle
          content
          video
        }
      }
    }
  }
`
